import { template as template_1ec7b97d4f7a46b3877130e9e1e6b7f8 } from "@ember/template-compiler";
const FKControlMenuContainer = template_1ec7b97d4f7a46b3877130e9e1e6b7f8(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
