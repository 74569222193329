import { template as template_6474120f85a248918d6e9d5414a05bfc } from "@ember/template-compiler";
const SidebarSectionMessage = template_6474120f85a248918d6e9d5414a05bfc(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
