import { template as template_4f19dc1c15134b8d957cd387ba0c3937 } from "@ember/template-compiler";
const WelcomeHeader = template_4f19dc1c15134b8d957cd387ba0c3937(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
